.sidebar {
  width: 100%;

  li { color: $color--lightgray }

  @include layout (mobile) {
    * { color: $color--white !important }
  }

  a {
    @include easing($easing-duration, color);
    &:hover,
    &.is-active { color: $color--dynamic }
  }

  &__content {
    @include hide-scroll__parent;

    position: relative;
    display: flex;
    flex-direction: column;

    @include layout (mobile) { padding: 0 $gutter }

    &.is-sticked {
      position: fixed;
      top: calc(#{$_menu--height} + #{$gutter});
    }
  }

  &__nav {
    @include hide-scroll__child(y);
    flex: 1;

    &-actions a {
      color: $color--dynamic
    }

    &-actions + .toc,
    &-actions + .filter {
      margin-top: 1em;
    }

    &-actions,
    .toc,
    .filter {
      @include font-size('sidebar');
      text-transform: lowercase;

      @include layout (mobile) { margin-top: $gutter }
    }
  }

  &__footer {
    @include font-size('link');
    line-height: inherit;

    .pagination {
      @include layout (mobile) { margin-top: $gutter }

      li {
        display: inline-block;
        padding-right: 1em;
      }
    }

    &-attachments {
      @include layout (mobile) {
        li:first-of-type { margin-top: $gutter }
      }
    }
  }
}
