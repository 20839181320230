@mixin no-select {
  -webkit-touch-callout: none;
  user-select: none;
}

@mixin debug ($color: red, $width: 1px) {
  outline: $width solid $color;
}

@mixin easing ($duration: 100ms, $property: all) {
  @if type-of($property) == list {
    $transitions: '';
    @each $p in $property {
      $transitions: #{$p} #{$duration} cubic-bezier(0.250, 0.100, 0.250, 1.000), #{$transitions};
    }
    transition: $transitions;
  } @else {
    transition: $property $duration cubic-bezier(0.250, 0.100, 0.250, 1.000);
  }
}

@mixin hide-scroll__parent {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@mixin hide-scroll__child($dir: y) {
  box-sizing: content-box;
  width: 100%;
  height: 100%;

  overflow: hidden;
  overflow-#{$dir}: scroll;
  @if $dir == x { padding-bottom: 17px }
  @if $dir == y { padding-right: 17px }
}

@mixin flex-grid ($cols: 3, $margin: 1rem) {
  width: calc(#{100% / $cols} - #{$margin - $margin / $cols});
  margin-right: $margin;
  &:nth-child(#{$cols}n) { margin-right: 0 }
}

@mixin invisible (){
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  pointer-events: none;
}

/**
 * $content: list(property, list(values)), list(property, list(values))
 */
@mixin cicada ($prop...) {
  @each $p in $prop {
    $property: nth($p, 1);
    $values: nth($p, 2);
    @if length($values) >= 1 { &:nth-child(2n+1) { #{$property}: #{nth($values, 1)}; } }
    @if length($values) >= 2 { &:nth-child(3n+2) { #{$property}: #{nth($values, 2)}; } }
    @if length($values) >= 3 { &:nth-child(5n+3) { #{$property}: #{nth($values, 3)}; } }
    @if length($values) >= 4 { &:nth-child(7n+5) { #{$property}: #{nth($values, 4)}; } }
    @if length($values) >= 5 { &:nth-child(11n+7) { #{$property}: #{nth($values, 5)}; } }
  }
}

@mixin progress-loader ($thickness: 1rem, $size: 50vw, $foreground: black, $background: transparent, $speed: 1s) {
 @keyframes loader-move {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(calc(100vw + 100%)); }
  }
  content: '';

  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: $size;

  border-top: $thickness solid $foreground;
  animation: loader-move $speed linear infinite;
}
