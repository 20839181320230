// NOTE: this is an opt-in reset
@mixin reset-input {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  color: inherit;
  font: inherit;
  line-height: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip:padding-box;
  -webkit-border-radius:0;
  -moz-border-radius:0;
  -ms-border-radius:0;
  -o-border-radius:0;
  border-radius:0;
  -webkit-appearance:none;
  color:#000;
  outline:0;
  margin:0;
  padding:0;
  text-align: left;
  font-size:1em;
  height: 1em;
  vertical-align: middle;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:focus { outline: none }
}


/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong,, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

ul, ol {
  list-style: none;
}

/* Don't kill focus outline for keyboard users: http://24ways.org/2009/dont-lose-your-focus */
a:hover, a:active {
    outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
