$hovered-link-fadedEl--selectors: p, ul, ol, table;

.article {
  margin-bottom: $gutter;

  &__excerpt-link {
    display: block;
    text-align: right;
    color: $color--dynamic;
  }

  &__header {
    *:last-child { margin-bottom: 1em }
    .label { color: $color--dynamic }

    &-title {
      @include font-size('h1');
      text-transform: uppercase;

      &-prefix {
        color: $color--dynamic;
        &::after {
          content: '\2009\2014\2009';
        }
      }
    }

    &-cover {
      &-link {
        display: block;
        margin-top: 1em;
        line-height: 0;
      }

      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }

    &-title + &-cover { margin-top: 1em }
  }

  &__content {
    .has-anchor { position: relative }
    .toc-anchor {
      position: absolute;
      top: calc(-#{$_menu--height} - #{$gutter});
    }

    h1, h2, h3, h4, h5, h6 {
      &:not(:first-child) { margin-top: 1em }
      margin-bottom: 1em;

      @include layout (print) { page-break-after: avoid }
    }

    h1 {
      @include font-size('h1');
      text-transform: uppercase;
      &:not(:first-child) { margin-top: 2em }
    }

    h2 {
      text-transform: uppercase;
    }

    h2, h3, h4, h5, h6 {
      @include font-size('h2');
    }

    #{$hovered-link-fadedEl--selectors} {
      @include font-size('text');
      @include easing($easing-duration, color);
    }

    &.has-hovered-link {
      #{$hovered-link-fadedEl--selectors} { color: $color--lightgray }
    }

    a:not(.unstyled-link) {
      font-weight: inherit;
      color: $color--black;
      // padding-bottom: 1px;
      border-bottom: 2px solid $color--dynamic;

      @include layout (print) {
        border: none;
        text-decoration: underline;

        &[href^="#"]::before {
          content: '\00b6\00a0';
        }

        &[href^="http"]::after{
          font-family: monospace;
          content: ' [\2192\00a0'attr(href)']';
        }
      }
    }

    audio {
      display: block;
      width: 100%;
      margin: 1em 0;
    }

    p { margin: 1em 0 }

    ol, ul {
      padding-left: 1em;
      margin: 1em 0;
    }

    ol { list-style: decimal outside }
    ul { list-style: disc outside }

    li { margin: 0 0.5em }

    table {
      @include font-size('table');
      border-collapse: collapse;
      width: 100%;
      margin: 1em 0;

      @include layout (print) { page-break-inside: avoid }

      th, td {
        text-align: left;
        vertical-align: middle;
        border: 2px solid $color--dynamic;
        padding: 1rem;
      }
    }

    iframe {
      width: 100%;
      height: auto;
    }

    figure {
      margin: 1em 0;
    }

    figure.video {
      margin: 1em 0;
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      width: 100%;
      height: auto;

      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    blockquote {
      margin: 1em 0;
      @include layout (print) { page-break-inside: avoid }

      em { font-style: normal }

      p {
        @include font-size('quote');
        font-style: italic;
      }
    }
  }
}
