.article-figure {
  &__wrapper {
    position: relative;
    width: 100%;
    @include layout (print) { width: 50% }
  }

  &__caption {
    @include font-size('quote');
    color: $color--lightgray;
    font-style: italic;
    text-align: left;
    margin: 1em 0;
  }

  &__link {
    cursor: zoom-in;
    display: block;
    line-height: 0;
  }

  &__image {
    line-height: 0;
    max-width: 100%;
    height: auto;
  }

  &__fullpreview {
    pointer-events: none;
    @include easing($easing-duration, opacity);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color--transparent-lightgray;
    display: flex;
    justify-content: center;
    align-items: center;


    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__fullpreview.is-open,
  &__wrapper:hover &__fullpreview { opacity: 1 }
}
