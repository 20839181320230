.publications {
  &__items {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    @include layout(mobile) { margin: $gutter }
  }

  &__item {
    @include layout(full) { @include flex-grid(4, 4rem) }
    @include layout(compact) { @include flex-grid(3, 4rem) }

    margin-bottom: $gutter;
    @include layout(mobile) {
      width: 100%;
      margin-bottom: calc(#{$gutter} * 2);
    }
  }
}

.publication {
  &__link { display: block }

  &__cover {
    margin-bottom: 1em;
    &--placeholder {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 141.8918919%; // A3 ratio
      overflow: hidden;
      pointer-events: none;

      background-color: $color--dynamic;
      &[data-color="black"] { background-color: $color--black }
      &[data-color="lightgray"] { background-color: $color--lightgray }
      &[data-color="blue"] { background-color: $color--blue }
      &[data-color="lightblue"] { background-color: $color--lightblue }
      &[data-color="green"] { background-color: $color--green }
      &[data-color="yellow"] { background-color: $color--yellow }
      &[data-color="red"] { background-color: $color--red }

      &-title {
        z-index: 1;
        position: absolute;
        left: 10%;
        right: 10%;
        bottom: 10%;
        font-size: 2.5rem;
        line-height: 1em;
        color: $color--black;
        text-transform: uppercase;
        &-logo {
          position: absolute;
          top: -0.5em;
          transform: translateY(-100%);
        }
      }
      &-background {
        position: absolute;
        bottom: 0.5em;
        width: 200%;
        left: -1ch;
        color: $color--white;
        font-size: 12rem;
        line-height: 1em;
        text-transform: uppercase;
        font-weight: normal;
        letter-spacing: 0.5ch;
      }
    }
  }
  &:hover .article-figure__fullpreview { opacity: 1 }

  .label { color: $color--dynamic }

  &__title {
    @include font-size('h3');
    text-transform: uppercase;
  }
}
