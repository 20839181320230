$menu__logo--height: 2.2rem;
// NOTE: see logo--full.php for hardcoded svg sizes
$menu__logo-full--width: $menu__logo--height * (167.7 / 15);

.menu {
  z-index: 2;
  position: fixed;
  background: $color--black;
  color: $color--white;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 $gutter;
  height: $_menu--height;
  display: flex;
  align-items: center;

  @include layout (print) {
    background: transparent;
    position: relative;
    margin: 0;
  }

  @include layout (mobile) { display: none }

  &__logo {
    position: relative;
    width: 33.333%;
    @include layout (compact) { width: auto }

    @include layout (print) {
      width: 100%;
      margin: 0 auto;
      max-width: 50ch;
    }

    svg,
    &--full,
    &--compact {
      display: block;
      height: $menu__logo--height;
      width: auto;
    }

    svg {
      fill: $color--white;
      @include layout (print) { fill: $color--black }
    }

    &--full,
    &-nav { @include easing($easing-duration, opacity) }

    &-nav {
      @include font-size('link');
      position: absolute;
      text-transform: lowercase;
      top: 0;
      left: 0;
      height: 100%;
      width: $menu__logo-full--width;

      &-items {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        height: 100%;
      }

      &-item {
        @include easing($easing-duration, color);
        width: auto;
        line-height: $menu__logo--height;
      }
    }
  }

  &:hover &__logo--full,
  &:not(:hover) &__logo-nav {
    pointer-events: none;
    opacity: 0;
  }

  &:hover &__nav-item:not(:hover):not(.is-open):not(.is-active),
  &:hover &__logo-nav-item:not(:hover):not(.is-open):not(.is-active) { color: $color--transparent-white }

  &__nav {
    flex: 1;
    width: 66.666%;
    max-width: 75vw;
    margin-left: $gutter;

    @include layout (compact) { max-width: 100vw }
    @include layout (print) { display: none }

    &-items {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-item {
      @include font-size('link');
      @include easing($easing-duration, color);

      white-space: nowrap;
      text-transform: lowercase;

      &:first-child { margin-left: -1rem }

      a {
        display: block;
        padding: 1rem;
      }
    }
  }
}
