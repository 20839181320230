/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * monteursassocies.com
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2018
 */

@font-face {
  font-family: 'Akkurat';
  src: url('../fonts/lineto-akkurat-regular.eot');
  src: url('../fonts/lineto-akkurat-regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/lineto-akkurat-regular.woff2') format('woff2'),
  url('../fonts/lineto-akkurat-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Akkurat';
  src: url('../fonts/lineto-akkurat-bold.eot');
  src: url('../fonts/lineto-akkurat-bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/lineto-akkurat-bold.woff2') format('woff2'),
  url('../fonts/lineto-akkurat-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Akkurat';
  src: url('../fonts/lineto-akkurat-italic.eot');
  src: url('../fonts/lineto-akkurat-italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/lineto-akkurat-italic.woff2') format('woff2'),
  url('../fonts/lineto-akkurat-italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
