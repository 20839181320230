.sitemap {
  ul {
    margin-left: 1em;
    list-style: circle outside;
  }

  & > ul {
    margin: 0;
    list-style: disc outside;

    & > li { margin-bottom: 1em }
  }
}
