.footer {
  @include font-size('link');
  background-color: $color--black;
  color: $color--white;
  padding: $gutter;

  display: flex;
  justify-content: space-between;

  li {
    display: inline-block;
    & + li { margin-left: $gutter }
  }

  a { @include easing($easing-duration, color) }
  &:hover a:not(:hover) { color: $color--transparent-white }

  #tempoLogo {
    @include no-select;
    margin-top: -0.7rem;
    margin-bottom: -1.2rem;

    img {
      max-height: 3rem;
      -ms-interpolation-mode: bicubic;
      vertical-align: bottom;
    }
  }

  @include layout (print) { display: none }
  @include layout (mobile, compact) {
    nav {
      display: flex;
      align-items: flex-end;
    }

    #tempoLogo {
      margin-bottom: 0;
      margin-top: 0;
      align-self: center;
    }

    nav + nav {
      text-align: right;
    }

    li {
      display: block;
      margin-bottom: 1em;
      margin-left: 0 !important;
    }
  }

  @media (max-width: 500px) {
    position: relative;
    padding-bottom: 5.5rem;
    #tempoLogo {
      position: absolute;
      margin: 0;
      align-self: unset;
      bottom: $gutter;
    }
  }
}
